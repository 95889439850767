var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"new-document-sidebar","visible":_vm.isNewDocumentSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-new-document-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Adicionar novo termo ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"url","vid":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{model:{value:(_vm.form.type_url),callback:function ($$v) {_vm.$set(_vm.form, "type_url", $$v)},expression:"form.type_url"}},[_c('option',{attrs:{"value":"download"}},[_vm._v("PDF")]),_c('option',{attrs:{"value":"external_link"}},[_vm._v("URL externa")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.form.type_url === 'download')?[_c('validation-provider',{attrs:{"name":"url","vid":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticClass:"mt-2",attrs:{"type":"file","accept":".pdf","placeholder":"Selecione o PDF","drop-placeholder":"solte o arquivo aqui..."},on:{"change":_vm.handleFileUpload},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.fileError))])]}}],null,true)})]:(_vm.form.type_url === 'external_link')?[_c('validation-provider',{attrs:{"name":"type_url","vid":"external_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-2",attrs:{"placeholder":"Cole a URL do termo de política e privacidade"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]:_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"success","type":"submit","disabled":_vm.isBusy},on:{"click":function($event){return _vm.submit()}}},[(_vm.isBusy)?_c('b-spinner'):[_vm._v(" Salvar ")]],2),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }