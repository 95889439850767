<template>
  <div>
    <b-card>
      <router-link
        :to="`/administrador`"
        class="link-none"
      >
        <feather-icon icon="ArrowLeftIcon" size="20" class="" />
      </router-link>
    </b-card>
    <b-card-group class="grid-container m-1" deck>
      <new-document
          v-if="isNewDocumentSidebarActive" 
          :is-new-document-sidebar-active.sync="isNewDocumentSidebarActive"
          @refresh="refresh()" 
          @close="isNewDocumentSidebarActive = false"
      />
      
      <b-link
        style="height: 300px;"
        class="link-none"
        @click="isNewDocumentSidebarActive = true"
      >
        <b-card style="padding-block: 50px;" class="text-center h-100">
          <feather-icon icon="ArrowUpIcon" size="70" class="mb-2" />
          <h2>Submeter política <br> de privacidade</h2>
        </b-card>
      </b-link>
      <b-link
        v-if="privacyPolicy && privacyPolicy.type_url === 'download'"
        style="height: 300px;"
        class="link-none"
        :href="pdfUrl"
        target="_blank"
      >
        <b-card style="padding-block: 50px;" class="text-center h-100">
          <feather-icon icon="ArrowDownIcon" size="70" class="mb-2" />
          <h2>Baixar termo</h2>
        </b-card>
      </b-link>
      <b-link
        v-if="privacyPolicy &&  privacyPolicy.type_url === 'external_link'"
        style="height: 300px;"
        class="link-none"
        :href="privacyPolicy.url"
        target="_blank"
      >
        <b-card style="padding-block: 50px;" class="text-center h-100">
          <feather-icon icon="InfoIcon" size="70" class="mb-2" />
          <h2>Visualizar termo</h2>
        </b-card>
      </b-link>
    </b-card-group>
  </div>
</template>
<script>
// ================================================================= //
import axios from "axios";
import { BCard } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import NewDocument from './NewDocument.vue'
// ================================================================= //
export default {
  components: {
    NewDocument,
    BCard,
  },
  setup() {
    const isNewDocumentSidebarActive = ref(false);
    return {
      isNewDocumentSidebarActive,
    };
  },
  data() {
    return {
      privacyPolicy: null,
      baseURL: process.env.VUE_APP_API_BASE,
      pdfUrl: null,
      token: localStorage.getItem('accessToken'),
    };
  },
  created() {
    this.fetchPrivacyPolicy();
  },
  methods: {
    fetchPrivacyPolicy() {
      const apiUrl = process.env.VUE_APP_API + `/privacy-policies/show`;

      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + this.token,
        }
      };

      axios
        .get(apiUrl, axiosConfig)
        .then((response) => {
          this.privacyPolicy = response.data.response;
          this.pdfUrl = this.baseURL + "/storage/policy_privacy/" + this.privacyPolicy.url;
          console.log(this.privacyPolicy);
        })
        .catch((error) => {
          console.error("Error fetching privacy policy:", error);
        });
      console.log(apiUrl);
    },
  },
};
</script>
<style>
  .link-none {
    text-decoration: none !important;
    color: inherit;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 27px;
  }

  @media (max-width: 768px) {
    .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }
  }
</style>
